html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;

  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 400;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
